import React, {FC} from "react";
import {ICity, ICityGroup} from "../types/types";

import MaterialTable from "@material-table/core";



interface ICityGroupsProps {
    cityGroups: ICityGroup[]
    createCityGroup: (city_group: ICityGroup) => void;
    updateCityGroup: (city_group_id: number, cityGroup: ICityGroup) => void;
}

const CityGroups: FC<ICityGroupsProps> = ({
                                              cityGroups,
                                              createCityGroup,
                                              updateCityGroup
                                         }) => {
    return (
        <>
            <MaterialTable
                columns={[
                    { title: "City Group", field: "name" },
                ]}
                options={{
                filtering: true
                }}
                data={cityGroups}
                title="City Group"
                editable={{
                    onRowAdd: newData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newCityGroup = newData as ICityGroup;
                                createCityGroup(newCityGroup);
                                //
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newCity = newData as ICityGroup;
                                if (typeof oldData === undefined){
                                    resolve();
                                    return;
                                }
                                const oldCity = oldData as ICity;

                                updateCityGroup(oldCity.id ?? 0, {"name": newCity.name});

                                resolve();
                            }, 1000)
                        }),
                }}
            />
        </>)
}


export default CityGroups