import React, {FC, useCallback, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import ButtonAppBar from "../components/menubar";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BuildIcon from '@mui/icons-material/Build';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import Users from "../components/users";
import Cities from "../components/cities";
import Paper from '@mui/material/Paper';
import Projects from "../components/projects";
import {
    ICities,
    ICity,
    ICityGroup, ICityGroupList, ICityGroups,
    IProject,
    IProjects, IProjectUser,
    IProjectUsers,
    IRole,
    IRoles,
    ITool,
    ITools,
    IUser, IUserGroup, IUserGroups, IUsers, IUserGroupList, ICityGroupListItem, ICityGroupListItems, IUserGroupListItems, IUserGroupListItem
} from "../types/types";
import {del, get, post, put} from "../fetchers/fetchers";
import {ENDPOINT} from "../config";
import ProjectUsers from "../components/projectusers";
import Roles from "../components/roles";
import Tools from "../components/tools";
import UserGroups from "../components/usergroups";
import UserGroupLists from "../components/usergrouplists";
import CityGroups from "../components/citygroups";
import CityGroupLists from "../components/citygrouplists";





const Overview: FC = () => {
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const [users, setUsers] = useState<IUser[]>([]);
    const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);
    const [userGroupLists, setUserGroupLists] = useState<IUserGroupListItem[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);
    const [cityGroups, setCityGroups] = useState<ICityGroup[]>([]);
    const [cityGroupLists, setCityGroupLists] = useState<ICityGroupListItem[]>([]);
    const [projects, setProjects] = useState<IProject[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [tools, setTools] = useState<ITool[]>([]);
    const [projectUsers, setProjectUsers] = useState<IProjectUser[]>([]);


    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const getUsers = async () => {
        const d = await get<IUsers>(ENDPOINT + "auth/users");
        setUsers(d.items)
    }

    const postUser = async (user: IUser) => {
        let cm = {...user};
        return await post<IUser>(ENDPOINT + "auth/register", cm)

    }

    const delUser= async (user_id: number) => {
        return await del(ENDPOINT + "auth/users/" + user_id)
    }

    const putUser = async (user_id: number, user: IUser) => {
        let cm = {...user};
        delete cm.id;
        delete cm.token_expires_in;
        delete cm.registered_on;

        console.log(user, cm)

        return await put<IUser>(ENDPOINT + "auth/users/" + user_id, cm)
    }

    const postUserGroup = async (userGroup: IUserGroup) => {
        let cm = {...userGroup};
        return await post<IUserGroup>(ENDPOINT + "auth/user_groups", cm)
    }

    const delUserGroup = async (user_group_id: number) => {
        return await del(ENDPOINT + "auth/user_groups/" + user_group_id)
    }

    const putUserGroup = async (user_group_id: number, user_group: IUserGroup) => {
        let cm = {...user_group};
        return await put<ICityGroup>(ENDPOINT + "auth/user_groups/" + user_group_id, cm)
    }


    const postUserGroupList = async (userGroupList: IUserGroupList) => {
        let cm = {...userGroupList};
        return await post<IUserGroupList>(ENDPOINT + "auth/user_group_lists", cm)
    }

    const delUserGroupList = async (city_group_list_id: number) => {
        return await del(ENDPOINT + "auth/user_group_lists/" + city_group_list_id)
    }

    const updateUserGroupList = useCallback((userGroupList: IUserGroupList) => {
        postUserGroupList(userGroupList).then(() => {
            setIsLoaded(false);
        })
    }, [postUserGroupList, setIsLoaded]);

    const createUser = useCallback((user: IUser) => {
        postUser(user).then(() => {
            setIsLoaded(false);
        })
    }, [postUser, setIsLoaded]);


    const updateUser = useCallback((user_id: number, user: IUser) => {
        putUser(user_id, user).then(() => {
            setIsLoaded(false);
        })
    }, [putUser, setIsLoaded]);



    const deleteUser = useCallback((user_id: number) => {
        // Delete user
        delUser(user_id).then(() => {
            setIsLoaded(false);
        })
        // If failed show error message from response
        .catch(error => {
            if (error.response) {
                setAlert(true)
                setAlertMessage(error.response.data.message)
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });

    }, [delUser, setIsLoaded]);

    const getUserGroups = async () => {
        const d = await get<IUserGroups>(ENDPOINT + "auth/user_groups");
        setUserGroups(d.items)
    }

    const updateUserGroup = useCallback((city_group_id: number, userGroup: IUserGroup) => {
        putUserGroup(city_group_id, userGroup).then(() => {
            setIsLoaded(false);
        })
    }, [putUserGroup, setIsLoaded]);

    const deleteUserGroup = useCallback((user_group_id: number) => {
        // Delete user
        delUserGroup(user_group_id).then(() => {
            setIsLoaded(false);
        })
            // If failed show error message from response
            .catch(error => {
                if (error.response) {
                    setAlert(true)
                    setAlertMessage(error.response.data.message)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

    }, [delUserGroup, setIsLoaded]);


    const deleteUserGroupList = useCallback((user_group_list_id: number) => {
        delUserGroupList(user_group_list_id).then(() => {
            setIsLoaded(false);
        })
    }, [delUserGroupList, setIsLoaded])

    const createUserGroup = useCallback((userGroup: IUserGroup) => {
        postUserGroup(userGroup).then(() => {
            setIsLoaded(false);
        })
    }, [postUserGroup, setIsLoaded]);


    const getCities = async () => {
        const d = await get<ICities>(ENDPOINT + "cities");
        setCities(d.items)
    }

    const getCityGroups = async () => {
        const d = await get<ICityGroups>(ENDPOINT + "cities/city_groups");
        setCityGroups(d.items)
    }

    const getCityGroupLists = async () => {
        const d = await get<ICityGroupListItems>(ENDPOINT + "cities/city_group_lists");
        setCityGroupLists(d.items)

    }

    const getUserGroupLists = async () => {
        const d = await get<IUserGroupListItems>(ENDPOINT + "auth/user_group_lists");
        setUserGroupLists(d.items)

    }

    const postCity = async (city: ICity) => {
        let cm = {...city};
        return await post<ICity>(ENDPOINT + "cities", cm)
    }
    const putCity = async (city_id: number, city: ICity) => {
        let cm = {...city};
        return await put<ICity>(ENDPOINT + "cities/" + city_id, cm)
    }

    const delCity = async (city_id: number) => {
        return await del(ENDPOINT + "cities/" + city_id)
    }

    const putCityGroup = async (city_group_id: number, city_group: ICityGroup) => {
        let cm = {...city_group};
        return await put<ICityGroup>(ENDPOINT + "cities/city_groups/" + city_group_id, cm)
    }

    const postCityGroup = async (cityGroup: ICityGroup) => {
        let cm = {...cityGroup};
        return await post<ICityGroup>(ENDPOINT + "cities/city_groups", cm)

    }

    const postCityGroupList = async (cityGroupList: ICityGroupList) => {
        let cm = {...cityGroupList};
        return await post<ICityGroupList>(ENDPOINT + "cities/city_group_lists", cm)
    }

    const delCityGroupList = async (city_group_list_id: number) => {
        return await del(ENDPOINT + "cities/city_group_lists/" + city_group_list_id)
    }

    const updateCity = useCallback((city_id: number, city: ICity) => {
        putCity(city_id, city).then(() => {
            setIsLoaded(false);
        })
    }, [putCity, setIsLoaded]);

    const deleteCity = useCallback((city_id: number) => {

        // Delete city
        delCity(city_id).then(() => {
            setIsLoaded(false);
        })
        // If failed show error message from response
        .catch(error => {
            if (error.response) {
                setAlert(true)
                setAlertMessage(error.response.data.message)
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });

    }, [delCity, setIsLoaded]);

    const createCity = useCallback((city: ICity) => {
        postCity(city).then(() => {
            setIsLoaded(false);
        })
    }, [postCity, setIsLoaded]);

    const updateCityGroup = useCallback((city_group_id: number, cityGroup: ICityGroup) => {
        putCityGroup(city_group_id, cityGroup).then(() => {
            setIsLoaded(false);
        })
    }, [putCityGroup, setIsLoaded]);

    const createCityGroup = useCallback((cityGroup: ICityGroup) => {
        postCityGroup(cityGroup).then(() => {
            setIsLoaded(false);
        })
    }, [postCityGroup, setIsLoaded]);

    const updateCityGroupList = useCallback((cityGroupList: ICityGroupList) => {
        postCityGroupList(cityGroupList).then(() => {
            setIsLoaded(false);
        })
    }, [postCityGroupList, setIsLoaded]);

    const deleteCityGroupList = useCallback((city_group_list_id: number) => {
        delCityGroupList(city_group_list_id).then(() => {
            setIsLoaded(false);
        })
    }, [delCityGroupList, setIsLoaded]);

    const postRole = async (role: IRole) => {
        let cm = {...role};
        return await post<IRole>(ENDPOINT + "roles", cm)

    }
    const delRole = async (role_id: number) => {
        return await del(ENDPOINT + "roles/" + role_id)
    }

    const putRole = async (role_id: number, role: IRole) => {
        let cm = {...role};
        return await put<IRole>(ENDPOINT + "roles/" + role_id, cm)
    }

    const putTool = async (tole_id: number, tool: ITool) => {
        let cm = {...tool};
        return await put<ITool>(ENDPOINT + "tools/" + tole_id, cm)
    }

    const delTool = async (tool_id: number) => {
        return await del(ENDPOINT + "tools/" + tool_id)
    }

    const createRole = useCallback((role: IRole) => {
        postRole(role).then(() => {
            setIsLoaded(false);
        })
    }, [postRole, setIsLoaded]);

    const updateRole= useCallback((role_id: number, role: IRole) => {
        putRole(role_id, role).then(() => {
            setIsLoaded(false);
        })
    }, [putRole, setIsLoaded]);

    const deleteRole= useCallback((role_id: number) => {
        delRole(role_id).then(() => {
            setIsLoaded(false);
        })        // If failed show error message from response
            .catch(error => {
                if (error.response) {
                    setAlert(true)
                    setAlertMessage(error.response.data.message)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }, [delRole, setIsLoaded]);

    const getProjects = async () => {
        const d = await get<IProjects>(ENDPOINT + "projects");
        setProjects(d.items)
    }

    const postProject = async (user: IProject) => {
        let cm = {...user};
        return await post<IProject>(ENDPOINT + "projects", cm)

    }
    const putProject = async (project_id: number, project: IProject) => {
        let cm = {...project};
        return await put<IProject>(ENDPOINT + "projects/" + project_id, cm)
    }

    const delProject = async (project_id: number) => {
        return await del(ENDPOINT + "projects/" + project_id)
    }

    const createProject = useCallback((project: IProject) => {
        postProject(project).then(() => {
            setIsLoaded(false);
        })
    }, [postProject, setIsLoaded]);


    const updateProject = useCallback((project_id: number, user: IProject) => {
        putProject(project_id, user).then(() => {
            setIsLoaded(false);
        })
    }, [putProject, setIsLoaded]);


    const deleteProject = useCallback((project_id: number) => {
        // Delete user
        delProject(project_id).then(() => {
            setIsLoaded(false);
        })
            // If failed show error message from response
            .catch(error => {
                if (error.response) {
                    setAlert(true)
                    setAlertMessage(error.response.data.message)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

    }, [delProject, setIsLoaded]);


    const getRoles = async () => {
        const d = await get<IRoles>(ENDPOINT + "roles");
        setRoles(d.items)
    }

    const getTools = async () => {
        const d = await get<ITools>(ENDPOINT + "tools");
        setTools(d.items)
    }

    const postTool = async (role: ITool) => {
        let cm = {...role};
        return await post<ITool>(ENDPOINT + "tools", cm)
    }

    const createTool = useCallback((tool: ITool) => {
        postTool(tool).then(() => {
            setIsLoaded(false);
        })
    }, [postTool, setIsLoaded]);

    const updateTool= useCallback((tool_id: number, tool: ITool) => {
        putTool(tool_id, tool).then(() => {
            setIsLoaded(false);
        })
    }, [putTool, setIsLoaded]);

    const deleteTool= useCallback((tool_id: number) => {
        delTool(tool_id).then(() => {
            setIsLoaded(false);
        })        // If failed show error message from response
            .catch(error => {
                if (error.response) {
                    setAlert(true)
                    setAlertMessage(error.response.data.message)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }, [delTool, setIsLoaded]);


    const getProjectUsers = async () => {
        const d = await get<IProjectUsers>(ENDPOINT + "projects/user_roles");
        setProjectUsers(d.items)
    }

    const postProjectUsers = async (user: IProjectUser) => {
        let cm = {...user};
        return await post<IProject>(ENDPOINT + "projects/user_roles", cm)

    }
    const delProjectGroupList= async (project_group_id: number) => {
        return await del(ENDPOINT + "projects/user_roles/" + project_group_id)
    }

    const updateProjectUsers = useCallback((project: IProjectUser) => {
        postProjectUsers(project).then(() => {
            setIsLoaded(false);
        })
    }, [postProjectUsers, setIsLoaded]);

    const deleteProjectGroupList = useCallback((project_group_id: number) => {
        // Delete user
        delProjectGroupList (project_group_id).then(() => {
            setIsLoaded(false);
        })
            // If failed show error message from response
            .catch(error => {
                if (error.response) {
                    setAlert(true)
                    setAlertMessage(error.response.data.message)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

    }, [delProjectGroupList, setIsLoaded]);





    useEffect(() => {
        if (!isLoaded && !isLoading) {
            setIsLoading(true)
            getCities().then(
                () => {
                    getProjects().then(
                        () => {
                            getUsers().then(
                                () => {
                                    getRoles().then(
                                        () => {
                                            getTools().then(
                                                () => {
                                                    getCityGroups().then(
                                                        () => {
                                                            getUserGroups().then(
                                                                () => {
                                                                    getProjectUsers().then(
                                                                        () => {
                                                                            getCityGroupLists().then(
                                                                                () => {
                                                                                    getUserGroupLists().then(
                                                                                        () => {
                                                                                            setIsLoaded(true)
                                                                                            setIsLoading(false)
                                                                                        })
                                                                                })
                                                                        })
                                                                })
                                                        })
                                                })
                                        })
                                })
                        })
                })
        }
    })


    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };


    const render_projects = () => {
        return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Projects projects={projects} updateProject={updateProject} createProject={createProject} deleteProject={deleteProject}/>
            </Grid>

            <Grid item xs={12} md={12}>
                <ProjectUsers projectUsers={projectUsers}
                              updateProjectUser={updateProjectUsers}
                              deleteProjectGroupList={deleteProjectGroupList}
                              projects={projects}
                              users={users}
                              userGroups={userGroups}
                              roles={roles}
                              tools={tools}
                              cities={cities}
                              cityGroups={cityGroups}
                              />
            </Grid>

        </Grid>
        )
    }

    const render_users = () => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Users users={users} createUser={createUser} updateUser={updateUser} deleteUser={deleteUser} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <UserGroups userGroups={userGroups} createUserGroup={createUserGroup} updateUserGroup={updateUserGroup} deleteUserGroup={deleteUserGroup}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <UserGroupLists userGroups={userGroups} users={users} userGroupList={userGroupLists} updateUserGroupList={updateUserGroupList}
                                    deleteUserGroupList = {deleteUserGroupList}/>
                </Grid>
            </Grid>
        )
    }

    const render_roles = () => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Roles roles={roles} createRole={createRole} updateRole={updateRole} deleteRole={deleteRole}/>
                </Grid>
            </Grid>
        )
    }

    const render_tools = () => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Tools tools={tools} updateTool={updateTool} createTool={createTool} deleteTool={deleteTool}/>
                </Grid>
            </Grid>
        )
    }


    const render_cities = () => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Cities cities={cities} updateCity={updateCity} createCity={createCity} deleteCity={deleteCity}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CityGroups cityGroups={cityGroups} createCityGroup={createCityGroup} updateCityGroup={updateCityGroup}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CityGroupLists cityGroups={cityGroups}
                                    cityGroupList={cityGroupLists}
                                    cities={cities}
                                    updateCityGroupList={updateCityGroupList}
                                    deleteCityGroupList={deleteCityGroupList}/>
                </Grid>
            </Grid>
        )
    }

    const drawerWidth = 240;
    return (
        <>

            <Box sx={{ display: 'flex' }}>

                <CssBaseline />
                <ButtonAppBar/>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Paper >
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                                <ListItem button key={"users"}
                                          onClick={(event) => handleListItemClick(event, 0)}>
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Users"} />
                                </ListItem>

                            <ListItem button key={"roles"}
                                      onClick={(event) => handleListItemClick(event, 1)}>
                                <ListItemIcon>
                                    <HowToRegIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Roles"} />
                            </ListItem>

                            <ListItem button key={"tools"}
                                      onClick={(event) => handleListItemClick(event, 2)}>
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Tools"} />
                            </ListItem>

                            <ListItem button key={"locations"}
                                      onClick={(event) => handleListItemClick(event, 3)}>
                                <ListItemIcon>
                                    <AddLocationIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Cities"} />
                            </ListItem>

                            <ListItem button key={"projects"}
                                      onClick={(event) => handleListItemClick(event, 4)}>
                                <ListItemIcon>
                                    <CollectionsBookmarkIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Projects"} />
                            </ListItem>
                        </List>
                    </Box>
                    </Paper>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                <Paper variant={"outlined"}>
                    {alert && <Alert severity={"error"} onClose={() => setAlert(false)}>{alertMessage}</Alert>}
                    {selectedIndex === 0 ? render_users() : <></> }
                    {selectedIndex === 1 ? render_roles() : <></> }
                    {selectedIndex === 2 ? render_tools() : <></> }
                    {selectedIndex === 3 ? render_cities() : <></> }
                    {selectedIndex === 4 ? render_projects() : <></> }
                </Paper>
            </Box>
            </Box>
        </>
    );
}

export default Overview;
