import React, {FC, useState} from "react";
import {ICity} from "../types/types";

import MaterialTable from "@material-table/core";



interface ICitiesAddProps {
    cities: ICity[]
    createCity: (city: ICity) => void;
    updateCity: (city_id: number, city: ICity) => void;
    deleteCity: (city_id: number) => void;

}

const Cities: FC<ICitiesAddProps>  = ({
                                          cities,
                                          createCity,
                                          updateCity,
                                          deleteCity
                                      }) => {



    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);


    const handleClose = () => {
        setOpenCreateDialog(false)
    }

    const handleOpen = () => {
        setOpenCreateDialog(true)
    }

    const handleUpdateCity = (city_id: number, city: ICity) => {
        updateCity(city_id, city)
    }

    return (
        <>
            <div>
            <MaterialTable
                columns={[
                    { title: "ID", field: "id" , editable: 'never'},
                    { title: "City", field: "name" },
                    { title: "Code", field: "code"},
                    { title: "Country", field: "country"},
                    { title: "State", field: "state"},
                    { title: "Longitude", field: "longitude"},
                    { title: "Latitude", field: "latitude"},
                    { title: "Scale", field: "scale"}
                    
                ]}
                options={{
                    filtering: true,
                    sorting: true,
                }}
                data={cities}
                title="City List"
                editable={{
                    onRowAdd: newData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newCity = newData as ICity;

                                createCity(newCity);

                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newCity = newData as ICity;
                                if (typeof oldData === undefined){
                                    resolve();
                                    return;
                                }
                                const oldCity = oldData as ICity;

                                updateCity(oldCity.id ?? 0, newCity);

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const oldCity = oldData as ICity;
                                if (typeof oldCity === undefined){
                                    resolve();
                                    return;
                                }
                                deleteCity(oldCity.id ?? 0);
                                resolve();
                            }, 1000)
                        }),
                }}
            />
            </div>
        </>)
}

export default Cities