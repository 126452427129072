import React, {FC, useCallback, useEffect, useState} from "react";
import {IUser, IUserGroup, IUserGroupListItem, IUserGroupList} from "../types/types";
import MaterialTable from "@material-table/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddUserGroupListDialog from "./addusergrouplistdlg";


interface IUSerGroupListsAddProps {
    userGroups: IUserGroup[];
    users: IUser[];
    userGroupList: IUserGroupListItem[];
    updateUserGroupList: (city: IUserGroupList) => void;
    deleteUserGroupList: (city_group_id: number) => void;
}

const UserGroupLists: FC<IUSerGroupListsAddProps> = ({
                                                         userGroups,
                                                         users,
                                                         userGroupList,
                                                         updateUserGroupList,
                                                         deleteUserGroupList
                                                     }) => {


    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [defaultGroupOrder, setDefaultGroupOrder] = useState<number>(0);


    const handleClose = () => {
        setOpenCreateDialog(false)
    }

    const handleOpen = (userGroups: IUserGroup[], cities: IUser[]) => {

        setOpenCreateDialog(true)
    }

    return (
        <>
            <div>
                {openCreateDialog && <AddUserGroupListDialog userGroups={userGroups} users={users}
                                                             updateUserGroupList={updateUserGroupList}
                                                             closeDialogCallback={handleClose}/>}
                <div>
                    <h2>Groups</h2>

                    <MaterialTable

                        columns={[
                            {
                                title: "Group", field: "user_group.id", defaultGroupOrder: defaultGroupOrder,
                                lookup: userGroups.reduce<Record<number, string>>(
                                    (acc, userGroup) => {
                                        acc[userGroup.id ?? 0] = userGroup.name;
                                        return acc;
                                    }, {} as any)

                            },

                            {
                                title: "User", field: "user.id", lookup: users.reduce<Record<number, string>>(
                                    (acc, user) => {
                                        acc[user.id ?? 0] = user.email ?? "";
                                        return acc;
                                    }, {} as any),

                                editComponent: props => (

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={users}
                                        onChange={(event: any, newValue: IUser | null) => {
                                            props.onChange(newValue ? newValue.id : '');
                                        }}
                                        getOptionLabel={(option) => option.email || ""}
                                        sx={{width: 300}}
                                        renderInput={params => {
                                            const inputProps = params.inputProps;
                                            // @ts-ignore
                                            inputProps.autoComplete = "off";
                                            return (
                                                <TextField
                                                    {...params}
                                                    inputProps={inputProps}
                                                    label="User"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                )


                            },
                        ]}
                        options={{
                            filtering: true,
                            sorting: true,
                            grouping: true,
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add User to Group',
                                isFreeAction: true,
                                onClick: (event) => {
                                    handleOpen(userGroups, users)
                                }
                            }
                        ]}


                        data={userGroupList}
                        title="User Group List"
                        editable={{

                            onRowDelete: oldData =>
                                new Promise<void>((resolve, reject) => {
                                    setTimeout(() => {
                                        if (oldData.id) {
                                            deleteUserGroupList(oldData.id)
                                        }
                                        resolve()
                                    }, 1000)
                                }),
                        }}
                    />
                </div>

            </div>
        </>
    )
}

export default UserGroupLists