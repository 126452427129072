import React, {FC, useState} from "react";

import {login, post, put} from "../fetchers/fetchers";
import {ENDPOINT} from "../config";
import {useNavigate, useLocation } from 'react-router-dom';
import {IPassword, IUser} from "../types/types";

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button';
import ForgotPassword from "./forgot_password";


interface IRestPasswordProps {

}

const ResetPassword: FC<IRestPasswordProps> = () => {

    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const token = query.get("token");
    const user_id = query.get("user_id");
    const origin_url = query.get("origin_url");



    const [password, setPassword] = useState<IPassword>({});
    const [errorMessage, setErrorMessage] = useState<string>()

    type keys = "password"

    const renderField = (label: string, key: keys, type: string) => {

        return <div>
            <div style={{
                width:"100%",
                margin:"auto",
            }}>
                <Typography>
                    {label}
                </Typography>
                <TextField
                    fullWidth
                    value={password[key]}
                    onChange={(event) => {
                        let v = (event.target.value as string);
                        let c = {...password};
                        c[key] = v
                        setPassword(c)
                    }
                    }
                    type={type}
                />
            </div>
        </div>
    }
    const reset_password = async (password: IPassword) => {
        return await put<IPassword>(ENDPOINT + "auth/users/" + user_id + "/reset_password/" + token, password);
    }

    const handleReset = () => {
        reset_password(password).then(() => {
            if (origin_url) {
                // Redirect to outside origin_url
                window.location.replace(origin_url)
            } else {
                navigate('/')
            }
        }).catch( (error) => {
            // Error
            if (error.response) {
                if (error.response.status == 403 || error.response.status == 401) {
                    setErrorMessage("Incorrect user name")
                }

            } else if (error.request) {
                setErrorMessage("Server is offline please try again later")
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage("This should not have happened")
            }
        })
    }


    return <Card elevation={8} style={{        minWidth:"300px",
        width:"40vw",
        margin:"auto",
        position:"relative"}}>
        <CardHeader
            title="Reset Password | WSCA User Management"
            subheader={
                <div>
                    <span style={{
                        color:"#f4f4f4",
                    }}>WSCA</span>
                    <span style={{
                        float: "right"
                    }}>
                            </span>
                </div>
            }
            style={{
                color:"#f4f4f4",
                backgroundColor:"#19b0ef"
            }}
        />
        <CardContent>
            <small style={{color:"red"}}>{errorMessage}</small>

            {renderField("New password", "password", "password")}
        </CardContent>
        <CardActions>
            <Button onClick={handleReset} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>reset</Button>
        </CardActions>
    </Card>

}

export default ResetPassword;