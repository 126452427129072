import React, {FC, useCallback, useEffect, useState} from "react";
import {IRole, IUserGroup} from "../types/types";

import MaterialTable from "@material-table/core";



interface IUserGroupsProps {
    userGroups: IUserGroup[]
    createUserGroup: (user_group: IUserGroup) => void;
    updateUserGroup: (user_group_id: number, cityGroup: IUserGroup) => void;
    deleteUserGroup: (user_group_id: number) => void;
}

const UserGroups: FC<IUserGroupsProps> = ({
                                              userGroups,
                                              createUserGroup,
                                              updateUserGroup,
                                              deleteUserGroup
                                         }) => {
    return (
        <>
            <MaterialTable
                columns={[
                    { title: "User Group", field: "name" },
                ]}
                options={{
                filtering: true
                }}
                data={userGroups}
                title="User Group"
                editable={{
                    onRowAdd: newData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newUserGroup = newData as IUserGroup;
                                createUserGroup(newUserGroup);
                                //
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newUserGroup = newData as IUserGroup;
                                if (typeof oldData === undefined){
                                    resolve();
                                    return;
                                }
                                const oldUserGroup = oldData as IUserGroup;

                                updateUserGroup(oldUserGroup.id ?? 0, {"name": newUserGroup.name});

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const oldUserGroup = oldData as IUserGroup;
                                if (typeof oldUserGroup === undefined) {
                                    resolve();
                                    return;
                                }
                                deleteUserGroup(oldUserGroup.id ?? 0);
                                resolve();
                            }, 1000)
                        }),
                }}
            />
        </>)
}


export default UserGroups