import axios from 'axios';

const getAuth = (): string  => {
    let token = localStorage.getItem("token");
    if (!token) {
        token = ""
    }

    return  "Bearer " + token
}
export async function get<T>(
    path: string
): Promise<T> {
    const {data} = await axios.get(path, {
        headers: {
            Authorization: getAuth()
        }
    });
    return data;
}

export async function del(
    path: string
): Promise<void> {
    await axios.delete(path, {
        headers: {
            Authorization: getAuth()
        }
    });
    return;
}



export async function post<T>(path: string, json: T):
    Promise<T> {
    const {data} = await axios.post(path, json,
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}

export async function put<T>(path: string, json: T):
    Promise<T> {
    const {data} = await axios.put(path, json,
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}

export async function postv2<T, RT>(path: string, json: T):
    Promise<RT> {
    const {data} = await axios.post(path, json,
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}



export async function login<T>(path: string, json: T):
    Promise<T> {
    const {data} = await axios.post(path, json,
        {
        });
    return data;
}


export async function download<T>(path: string): Promise<T> {
    const {data} = await axios.get(path,
        {
            headers: {
                Authorization: getAuth()

            },
            responseType: 'blob'
        });
    return data

}


export async function upload<T>(path: string, file: T):
    Promise<T> {
    const {data} = await axios.post(path, file,
        {
            headers: {
                Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYxNzgzODk4OSwianRpIjoiMTkyNzZmMjctOTJhOC00ZGE3LWIzY2ItYzgzY2Q4NjkyZjZmIiwibmJmIjoxNjE3ODM4OTg5LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoxfQ.3kUM5VPQsjYQgi92lgJJFFsGIDZ1Bx35mRmNPiABAUxBnuewKjMUcPg-IVa3SBS9rtMyhMXCgowVLn511FbG_g",
                'Content-Type': 'multipart/form-data'
            }
        });
    return data;
}