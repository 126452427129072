import React, {FC, useCallback, useEffect, useState} from "react";
import MaterialTable from "@material-table/core";

import {ICity, ITool} from "../types/types";


interface IToolAddProps {
    tools: ITool[]
    createTool: (tool: ITool) => void;
    updateTool: (tool_id: number, tool: ITool) => void;
    deleteTool: (tool_id: number) => void;
}

const Tools: FC<IToolAddProps> = ({
                                      tools,
                                      createTool,
                                      updateTool,
                                      deleteTool
                                  }) => {


    return (
        <>
            <div>
                <MaterialTable
                    columns={[
                        {title: "ID", field: "id", editable: "never"},
                        {title: "Tool", field: "name"}
                    ]}
                    options={{
                        filtering: true,
                        sorting: true
                    }}
                    data={tools}
                    title="Tools"
                    editable={{
                        onRowAdd: newData =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const newTool = newData as ITool;
                                    createTool(newTool);
                                    //
                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const newTool = newData as ITool;
                                    if (typeof oldData === undefined) {
                                        resolve();
                                        return;
                                    }
                                    const oldTool = oldData as ITool;

                                    updateTool(oldTool.id ?? 0, {"name": newTool.name});

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const oldTool = oldData as ITool;
                                    if (typeof oldTool === undefined) {
                                        resolve();
                                        return;
                                    }
                                    deleteTool(oldTool.id ?? 0);
                                    resolve();
                                }, 1000)
                            }),
                    }}

                />
            </div>
        </>)
}

export default Tools