import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {ICityGroups, ICityGroup, IUser, ICityGroupList, ICity, ICityGroupListItem} from "../types/types";
import {ENDPOINT} from "../config";
import {get, post} from "../fetchers/fetchers";
import MaterialTable from "@material-table/core";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AddCityGroupListDialog from "./addcitygrouplistdlg";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


interface ICityGroupsAddProps {
    cityGroups: ICityGroup[];
    cities: ICity[];
    cityGroupList: ICityGroupListItem[];
    updateCityGroupList: (city: ICityGroupList) => void;
    deleteCityGroupList: (city_group_id: number) => void;
}

const CityGroupLists: FC<ICityGroupsAddProps> = ({
                                                     cityGroups,
                                                     cities,
                                                     cityGroupList,
                                                     updateCityGroupList,
                                                     deleteCityGroupList
                                                 }) => {



    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [currentCityGroup, setCurrentCityGroup] = useState<ICityGroup | undefined >();

    const [defaultGroup, setCityGroupListItems] = useState<ICityGroupListItem[]>([]);

    const [defaultGroupOrder, setDefaultGroupOrder] = useState<number>(0);

    const handleClose = () => {
        setOpenCreateDialog(false)
    }

    const handleOpen = (cityGroups: ICityGroup[], cities:ICity[]) => {

        setOpenCreateDialog(true)
    }

    return (
        <>
            <div>
                {openCreateDialog && <AddCityGroupListDialog cityGroups={cityGroups} cities={cities} updateCityGroupList={updateCityGroupList} closeDialogCallback={handleClose}/>}
                        <div>
                            <h2>Groups</h2>

                            <MaterialTable

                                columns={[
                                    { title: "Group", field: "city_group.id", defaultGroupOrder: defaultGroupOrder,
                                        lookup: cityGroups.reduce<Record<number, string>>(
                                            (acc, cityGroup) => {
                                                acc[cityGroup.id ?? 0] = cityGroup.name;
                                                return acc;
                                            }, {} as any)

                                    },

                                    { title: "City", field: "city.id",  lookup: cities.reduce<Record<number, string>>(
                                            (acc, city) => {
                                                acc[city.id ?? 0] = city.name;
                                                return acc;
                                            }, {} as any),

                                        editComponent: props => (

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={cities}
                                                onChange={(event: any, newValue: ICity | null) => {
                                                    props.onChange(newValue ? newValue.id : '');
                                                }}
                                                getOptionLabel={(option) => option.name || ""}
                                                sx={{ width: 300 }}
                                                renderInput={params => {
                                                    const inputProps = params.inputProps;
                                                    // @ts-ignore
                                                    inputProps.autoComplete = "off";
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            inputProps={inputProps}
                                                            label="City"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    );
                                                }}
                                            />
                                        )


                                    },
                                ]}
                                options={{
                                    filtering: true,
                                    sorting: true,
                                    grouping: true,
                                }}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add City to Group',
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            handleOpen(cityGroups, cities)
                                        }
                                    }
                                ]}


                                data={cityGroupList}
                                title="City Group List"
                                editable={{

                                    onRowDelete: oldData =>
                                        new Promise<void>((resolve, reject) => {
                                            setTimeout(() => {
                                                if (oldData.id) {
                                                    deleteCityGroupList(oldData.id)
                                                }
                                                resolve()
                                            }, 1000)
                                        }),
                                }}
                            />
                        </div>

            </div>
        </>
    )
}


export default CityGroupLists