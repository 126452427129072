import React, {FC, useState} from "react";

import {login, post} from "../fetchers/fetchers";
import {ENDPOINT} from "../config";
import {useNavigate} from 'react-router-dom';
import {IUser} from "../types/types";

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button';


interface ILogin {
}

const Login: FC<ILogin> = () => {

    const navigate = useNavigate();

    const [user, setUser] = useState<IUser>({});
    const [errorMessage, setErrorMessage] = useState<string>()

    type keys = "email" | "password" | "access_token"

    const renderField = (label: string, key: keys, type: string) => {
        return <div>
            <div style={{
                width:"100%",
                margin:"auto",
            }}>
                <Typography>
                    {label}
                </Typography>
                <TextField
                    // error={!!this.state.screen_error_state[key]}
                    // helperText={this.state.screen_error_state[key]}
                    fullWidth
                    value={user[key]}
                    onChange={(event) => {
                        let v = (event.target.value as string);
                        let c = {...user};
                        c[key] = v
                        setUser(c)
                        }
                    }
                    type={type}
                />
            </div>
        </div>
    }

    const getToken = async (user: IUser) => {
        return await login<IUser>(ENDPOINT + "auth/login", user);
    }

    const setUserStorage = (user: IUser) => {
        if (user.email) {
            localStorage.setItem("email", user.email)
        }
        if (user.access_token) {
            localStorage.setItem("token", user.access_token)
        }
    }

    const handleLogin = () => {
        getToken(user).then((d) => {
            setUserStorage(d)
            navigate('/management')
        }).catch( (error) => {

            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                if (error.response.status == 403 || error.response.status == 401) {
                    setErrorMessage("Incorrect user name or password")
                }


            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                setErrorMessage("Server is offline please try again later")
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage("This should not have happened")
                //console.log('Error', error.message);
            }
        })
    }

    const handleReset = () => {
            navigate('/forgot_password')
    }


    return <Card elevation={8} style={{        minWidth:"300px",
        width:"40vw",
        margin:"auto",
        position:"relative"}}>
        <CardHeader
            title="Log In | WSCA User Management"
            subheader={
                <div>
                    <span style={{
                        color:"#f4f4f4",
                    }}>WSCA</span>
                    <span style={{
                        float: "right"
                    }}>
                    {/*<Button onClick={this.handleHelp} size={"small"} variant={"outlined"} style={{color:"#f4f4f4", borderColor:"#f4f4f4"}}>Documentation</Button>*/}
                            </span>
                </div>
            }
            style={{
                color:"#f4f4f4",
                backgroundColor:"#19b0ef"
            }}
        />
        <CardContent>
            <div style={ {textAlign: "right"} } > <small> Build {process.env.REACT_APP_VERSION} </small><br /></div>
            <small style={{color:"red"}}>{errorMessage}</small>

            {renderField("Email", "email", "email")}
            <br/>
            {renderField("Password", "password", "password")}
        </CardContent>
        <CardActions>

            {/*<Button onClick={this.setScreen("register")} size={"small"} variant={"outlined"}>Register</Button>*/}
            <Button onClick={handleReset} size={"small"} variant={"outlined"}>Reset password</Button>
            <Button onClick={handleLogin} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>login</Button>
        </CardActions>
    </Card>

}

export default Login;
