import React, {FC, useCallback, useEffect, useState} from "react";
import MaterialTable from "@material-table/core";

import {ICityGroup, IRole, ITool} from "../types/types";


interface IUsersAddProps {
    roles: IRole[]
    createRole: (user: IRole) => void;
    updateRole: (role_id: number, role: IRole) => void;
    deleteRole: (tool_id: number) => void;
}

const Roles: FC<IUsersAddProps> = ({
                                       roles,
                                       createRole,
                                       updateRole,
                                       deleteRole
                                   }) => {


    return (
        <>
            <div>

                <MaterialTable
                    columns={[
                        {title: "ID", field: "id", editable: "never"},
                        {title: "Role", field: "name"}
                    ]}
                    options={{
                        filtering: true,
                        sorting: true
                    }}
                    data={roles}
                    title="Roles"
                    editable={{
                        onRowAdd: newData =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const newRole = newData as IRole;
                                    createRole(newRole);
                                    //
                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const newRole = newData as IRole;
                                    if (typeof oldData === undefined) {
                                        resolve();
                                        return;
                                    }
                                    const oldRole = oldData as IRole;

                                    updateRole(oldRole.id ?? 0, {"name": newRole.name});

                                    resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise<void>((resolve, reject) => {
                                setTimeout(() => {
                                    const oldRole = oldData as IRole;
                                    if (typeof oldRole === undefined) {
                                        resolve();
                                        return;
                                    }
                                    deleteRole(oldRole.id ?? 0);
                                    resolve();
                                }, 1000)
                            }),
                    }}
                />
            </div>
        </>)
}


export default Roles