import React, {FC, useCallback, useEffect, useState} from "react";
import {ICity, IUser, IUsers} from "../types/types";
import {ENDPOINT} from "../config";
import {get, post} from "../fetchers/fetchers";
import MaterialTable from "@material-table/core";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AddUserDialog from "./adduserdlg";

interface IUsersAddProps {
    users: IUser[]
    createUser: (user: IUser) => void;
    updateUser: (user_id: number, user: IUser) => void;
    deleteUser: (user_id: number) => void;
}

const Users: FC<IUsersAddProps> = ({
                                        users,
                                        createUser,
                                        updateUser,
                                        deleteUser
                                   }) => {


    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);


    const handleClose = () => {
        setOpenCreateDialog(false)
    }

    const handleOpen = () => {
        setOpenCreateDialog(true)
    }

    return (
        <>
            <div>
            <MaterialTable
                columns={[
                    { title: "ID", field: "id",  editable: 'never' },
                    { title: "Email", field: "email"},
                    { title: "Password", field: "password", emptyValue: "*******"},
                    { title:  "Active", field: "active", type: "boolean"},
                    { title:  "Registered", field: "registered_on",  editable: 'never'}
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newUser = newData as IUser;
                                createUser(newUser);
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                    new Promise<void>((resolve, reject) => {
                        setTimeout(() => {
                            const newUser = newData as IUser;
                            if (typeof oldData === undefined){
                                resolve();
                                return;
                            }
                            const oldUser = oldData as IUser;

                            updateUser(oldUser.id ?? 0, newUser);

                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise<void>((resolve, reject) => {
                        setTimeout(() => {
                            const oldUser = oldData as IUser;
                            if (typeof oldUser === undefined){
                                resolve();
                                return;
                            }
                            deleteUser(oldUser.id ?? 0);
                            resolve();
                        }, 1000)
                    }),
                }}
                options={{
                filtering: true,
                    sorting: true
                }}
                data={users}
                title="User List"
            />
            </div>

        </>)
}


export default Users