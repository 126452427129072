import React, {FC} from "react";
import { IProject} from "../types/types";

import MaterialTable from "@material-table/core";


interface IProjectsAddProps {
    projects: IProject[]
    createProject: (project: IProject) => void;
    updateProject: (project_id: number, tool: IProject) => void;
    deleteProject: (project_id: number) => void;
}

const Projects: FC<IProjectsAddProps> = ({
                                             projects,
                                             createProject,
                                             updateProject,
                                             deleteProject
                                         }) => {



    return (
        <>
            <div>

            <MaterialTable
                columns={[
                    {title: "ID", field: "id", editable: "never"},
                    { title: "Project", field: "name" },
                ]}
                options={{
                    filtering: true,
                    sorting: true
                }}
                data={projects}
                title="Projects"
                editable={{
                    onRowAdd: newData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newTool = newData as IProject;
                                createProject(newTool);
                                //
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const newProject = newData as IProject;
                                if (typeof oldData === undefined) {
                                    resolve();
                                    return;
                                }
                                const oldProject = oldData as IProject;

                                updateProject(oldProject.id ?? 0, {"name": newProject.name});

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const oldProject = oldData as IProject;
                                if (typeof oldProject === undefined) {
                                    resolve();
                                    return;
                                }
                                deleteProject(oldProject.id ?? 0);
                                resolve();
                            }, 1000)
                        }),
                }}
            />
            </div>

        </>)
}


export default Projects