import React, {FC, useCallback, useState} from "react";
import {
    ICity,
    ICityGroup,
    IProject, IProjectUser, IRole, ITool,
    IUser, IUserGroup, IUserGroups
} from "../types/types";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Autocomplete from '@mui/material/Autocomplete';

interface IProjectUserAddProps {
    projectUser: IProjectUser
    updateProjectUser: (project: IProjectUser) => void;
    closeDialogCallback: () => void;
    projects: IProject[];
    tools: ITool[];
    roles: IRole[];
    users: IUser[];
    userGroups: IUserGroup[];
    cities: ICity[];
    cityGroups: ICityGroup[];

}

const AddProjectDialog: FC<IProjectUserAddProps> = ({
                                                        projectUser,
                                                        updateProjectUser,
                                                        closeDialogCallback,
                                                        projects,
                                                        tools,
                                                        roles,
                                                        cities, 
                                                        cityGroups,
                                                        users,
                                                    userGroups}) => {

    const [newProjectUser, setNewProjectUser]= useState<IProjectUser>(projectUser);
    const [project, setProject] = useState<IProject | null>();
    const [tool, setTool] = useState<ITool | null>();
    const [role, setRole] = useState<IRole | null>();
    const [user, setUser] = useState<IUser | null>();
    const [userGroup, setUserGroup] = useState<IUserGroup | null>();
    const [city, setCity] = useState<ICity | null>(null);
    const [city_group, setCityGroup] = useState<ICityGroup | null>(null);

    const submitControlMeasure = () => {
        let ra = {...newProjectUser}
        ra.project_id = project?.id || 0
        ra.tool_id = tool?.id || 0
        ra.user_id = user?.id || 0
        ra.user_group_id = userGroup?.id || 0
        ra.role_id = role?.id || 0
        ra.city_id = city?.id || 0
        ra.city_group_id = city_group?.id || 0
        updateProjectUser(ra)
        closeDialogCallback()
    }

    return (  <Dialog open={true} onClose={closeDialogCallback} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add User Project
                </DialogContentText>
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={projects}
                    onChange={(event: any, newValue: IProject | null) => {
                        setProject(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Project" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={users}
                    onChange={(event: any, newValue: IUser | null) => {
                        setUser(newValue);
                    }}
                    getOptionLabel={(option) => option.email || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="User" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={userGroups}
                    onChange={(event: any, newValue: IUserGroup | null) => {
                        setUserGroup(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="User Group" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={roles}
                    onChange={(event: any, newValue: IRole | null) => {
                        setRole(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Role" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={tools}
                    onChange={(event: any, newValue: ITool | null) => {
                        setTool(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Tool" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={cities}
                    onChange={(event: any, newValue: ICity | null) => {
                        setCity(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="City" />}
                />
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={cityGroups}
                    onChange={(event: any, newValue: ICityGroup | null) => {
                        setCityGroup(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="City Group" />}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialogCallback} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitControlMeasure} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddProjectDialog;
