
import React, {FC, useState} from "react";

import {login, post, put} from "../fetchers/fetchers";
import {ENDPOINT} from "../config";
import {useNavigate, useLocation } from 'react-router-dom';
import {IPassword, IUser, IUserEmail} from "../types/types";

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button';


interface IRestPasswordProps {

}

const ForgotPassword: FC<IRestPasswordProps> = () => {

    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState<IUserEmail>({});
    const [errorMessage, setErrorMessage] = useState<string>()

    type keys = "email"

    const renderField = (label: string, key: keys, type: string) => {

        return <div>
            <div style={{
                width:"100%",
                margin:"auto",
            }}>
                <Typography>
                    {label}
                </Typography>
                <TextField
                    fullWidth
                    value={userEmail[key]}
                    onChange={(event) => {
                        let v = (event.target.value as string);
                        let c = {...userEmail};
                        c[key] = v
                        setUserEmail(c)
                    }
                    }
                    type={type}
                />
            </div>
        </div>
    }

    const reset_password = async (userEmail: IUserEmail) => {
        return await post<IUserEmail>(ENDPOINT + "auth/users/forgot_password", userEmail);
    }

    const handleReset = () => {
        reset_password(userEmail).then(() => {
            navigate('/')
        }).catch( (error) => {

            // Error
            if (error.response) {
                if (error.response.status == 403){
                    setErrorMessage("Incorrect user name or password")
                }

            } else if (error.request) {
                setErrorMessage("Server is offline please try again later")
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage("This should not have happened")
                //console.log('Error', error.message);
            }
        })
    }


    return <Card elevation={8} style={{        minWidth:"300px",
        width:"40vw",
        margin:"auto",
        position:"relative"}}>
        <CardHeader
            title="Reset Password | WSCA User Management"
            subheader={
                <div>
                    <span style={{
                        color:"#f4f4f4",
                    }}>WSCA</span>
                    <span style={{
                        float: "right"
                    }}>
                            </span>
                </div>
            }
            style={{
                color:"#f4f4f4",
                backgroundColor:"#19b0ef"
            }}
        />
        <CardContent>
            <small style={{color:"red"}}>{errorMessage}</small>

            {renderField("Email", "email", "text")}
            We will send you a link to reset your password. Please check your spam folder if you do not see the email.
        </CardContent>
        <CardActions>
            <Button onClick={handleReset} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>Request</Button>
        </CardActions>
    </Card>

}

export default ForgotPassword;
