import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Route, Routes} from "react-router";
import Login from "./components/login";
import Overview from "./screens/overview";
import ResetPassword from "./components/reset_password";
import ForgotPassword from "./components/forgot_password";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path='/management' element={<Overview/>}/>
                    <Route path='/' element={<Login/>}/>
                    <Route path='/reset_password' element={<ResetPassword/>}/>
                    <Route path='/forgot_password' element={<ForgotPassword/>}/>
                </Routes>
            </BrowserRouter>

        </div>
    );
}


export default App;
