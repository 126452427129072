import React, {FC, useCallback, useState} from "react";
import {

    IUser, IUserGroup, IUserGroupList
} from "../types/types";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Autocomplete from '@mui/material/Autocomplete';

interface IUserGroupListAddProps {
    userGroups: IUserGroup[],
    users: IUser[],
    updateUserGroupList: (userGroupList: IUserGroupList) => void;
    closeDialogCallback: () => void;
}

const AddUserGroupListDialog: FC<IUserGroupListAddProps> = ({
                                                                userGroups,
                                                                users,
                                                                updateUserGroupList,
                                                                closeDialogCallback
                                                            }) => {

    const [userGroup, setUserGroup] = useState<IUserGroup | null>(userGroups[0]);
    const [user, setUser] = useState<IUser | null>(users[0]);

    const submitUserGroup = () => {
        updateUserGroupList({
            "user_group_id": userGroup?.id || 0,
            "user_id": user?.id || 0,


        })
        closeDialogCallback()
    }

    return (<Dialog open={true} onClose={closeDialogCallback} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add User to Group </DialogTitle>
            <DialogContent>
                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={userGroups}
                    onChange={(event: any, newValue: IUserGroup | null) => {
                        setUserGroup(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{width: 300}}
                    renderInput={params => {
                        const inputProps = params.inputProps;
                        // @ts-ignore
                        inputProps.autoComplete = "new-password";
                        return (
                            <TextField
                                {...params}
                                inputProps={inputProps}
                                label="User Group"
                                variant="outlined"
                                onBlur={event => console.log(event.target.value)}
                                fullWidth
                            />
                        );
                    }
                    }
                />
                <p></p>

                <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={users}
                    onChange={(event: any, newValue: IUser | null) => {
                        setUser(newValue);
                    }}
                    getOptionLabel={(option) => option.email || ""}
                    sx={{width: 300}}
                    renderInput={params => {
                        const inputProps = params.inputProps;
                        // @ts-ignore
                        inputProps.autoComplete = "new-password";
                        return (
                            <TextField
                                {...params}
                                inputProps={inputProps}
                                label="User"
                                variant="outlined"
                                onBlur={event => console.log(event.target.value)}
                                fullWidth
                            />
                        );
                    }
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialogCallback} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitUserGroup} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddUserGroupListDialog;
