import React, {FC, useCallback, useState} from "react";
import {
    ICity,
    ICityGroup, ICityGroupList,
    IUser
} from "../types/types";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Autocomplete from '@mui/material/Autocomplete';

interface ICityGroupListAddProps {
    cityGroups: ICityGroup[],
    cities:ICity[],
    updateCityGroupList: (cityGroupList: ICityGroupList) => void;
    closeDialogCallback: () => void;
}

const AddCityGroupListDialog: FC<ICityGroupListAddProps> = ({
                                                                cityGroups,
                                                        cities,
                                                        updateCityGroupList,
                                                        closeDialogCallback
                                                    }) => {

    const [cityGroup, setCityGroup] = useState<ICityGroup | null>(cityGroups[0]);
    const [city, setCity] = useState<ICity | null>(cities[0]);

    const submitCityGroup = () => {
        updateCityGroupList({   "city_group_id" : cityGroup?.id || 0,
                                "city_id" : city?.id || 0,


        })
        closeDialogCallback()
    }

    return (  <Dialog open={true} onClose={closeDialogCallback} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add City to City group</DialogTitle>
            <DialogContent>
                <p></p>
                <Autocomplete

                    disablePortal={false}
                    id="combo-box-demo"
                    options={cityGroups}
                    onChange={(event: any, newValue: ICityGroup | null) => {
                        setCityGroup(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={params => {
                        const inputProps = params.inputProps;
                        // @ts-ignore
                        inputProps.autoComplete = "new-password";
                        return (
                            <TextField
                                {...params}
                                inputProps={inputProps}
                                label="City Group"
                                variant="outlined"
                                onBlur={event => console.log(event.target.value)}
                                fullWidth
                            />
                        );
                    }
                    }

                />
                <p></p>
                <Autocomplete
                    autoComplete={false}
                    disablePortal={false}
                    id="combo-box-demo"
                    options={cities}
                    onChange={(event: any, newValue: ICity | null) => {
                        setCity(newValue);
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: 300 }}
                    renderInput={params => {
                        const inputProps = params.inputProps;
                        // @ts-ignore
                        inputProps.autoComplete = "new-password";
                        return (
                            <TextField
                                {...params}
                                inputProps={inputProps}
                                label="City"
                                variant="outlined"
                                onBlur={event => console.log(event.target.value)}
                                fullWidth
                            />
                        );
                    }
                    }
                />
                

            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialogCallback} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitCityGroup} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCityGroupListDialog;
