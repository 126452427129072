import React, {FC, useCallback, useEffect, useState} from "react";
import {
    IProjectUsers,
    IProjectUser,
    ICity,
    IProject,
    IUser,
    IRole,
    ITools,
    ITool,
    ICityGroup,
    IUserGroup
} from "../types/types";
import {ENDPOINT} from "../config";
import {get, post} from "../fetchers/fetchers";
import MaterialTable from "@material-table/core";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AddProjectUserDialog from "./addprojectuserdlg";



interface IProjectUsersAddProps {
    projectUsers: IProjectUser[];
    updateProjectUser: (project_user: IProjectUser) => void;
    projects: IProject[];
    users: IUser[];
    userGroups: IUserGroup[],
    roles: IRole[];
    cities: ICity[];
    cityGroups: ICityGroup[];
    tools: ITool[];
    deleteProjectGroupList: (project_group_list: number) => void;

}

const ProjectUsers: FC<IProjectUsersAddProps> = ({
                                                     projectUsers,
                                                     updateProjectUser,
                                                     deleteProjectGroupList,
                                                     projects,
                                                     users,
                                                     userGroups,
                                                     roles,
                                                     cities,
                                                     cityGroups,
                                                     tools
                                         }) => {


    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);


    const handleClose = () => {
        setOpenCreateDialog(false)
    }

    const handleOpen = () => {
        setOpenCreateDialog(true)
    }

    return (
        <>
            <div>

                {openCreateDialog && <AddProjectUserDialog projectUser={{

                }}
                                                           updateProjectUser={updateProjectUser}
                                                           projects={projects}
                                                           roles={roles}
                                                           users={users}
                                                           tools={tools}
                                                           cities={cities}
                                                           cityGroups={cityGroups}
                                                           userGroups={userGroups}
                                                           closeDialogCallback={handleClose}/>}

            <MaterialTable
                columns={[
                    { title: "Project", field: "project.name" , defaultGroupOrder:0},
                    { title: "City", field: "city.name" },
                    { title: "City Group", field: "city_group.name" },
                    { title: "Tool", field: "tool.name" },
                    { title: "User", field: "user.email" },
                    { title: "User Group", field: "user_group.name" },
                    { title: "Role", field: "role.name" },
                ]}
                options={{
                filtering: true, grouping: true,
                    sorting: true
                }}
                data={projectUsers}
                title="Project User List"
                editable={{

                    onRowDelete: oldData =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                console.log(oldData)
                                if (oldData.id) {
                                    deleteProjectGroupList(oldData.id)
                                }
                                resolve()
                            }, 1000)
                        }),

                }}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add City to Group',
                        isFreeAction: true,
                        onClick: (event) => {
                            handleOpen()
                        }
                    }
                ]}
            />
            </div>

        </>)
}


export default ProjectUsers